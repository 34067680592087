import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import RecentlyMatchedContainer from "../components/FormBuilder/RecentlyMatched/RecentlyMatchedContainer";
import "../styles/form.scss";

const RecentlyMatched = () => (
  <AuthenticationLayout>
    <RecentlyMatchedContainer />
  </AuthenticationLayout>
);

export default RecentlyMatched;

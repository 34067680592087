import Agent from "../../../Interfaces/agent";
import { FieldTypes } from "../../../utils/fieldTypes";

const RecentlyMatchedAgentDefaultFields = (agent: Agent, index: number) => {
  const fields = [
    {
      type: FieldTypes.header,
      keyName: `agentHeader${index}`,
      label: `${agent.firstname} ${agent.lastname} ${
        agent.brokerage_name ? `of ${agent.brokerage_name}` : ""
      }?`,
    },
    {
      label: `Have you spoken with ${agent.firstname} ${agent.lastname} via text, email or phone call?`,
      keyName: `have_you_spoken_with_agent_${
        index + 1
      }__via_text__email__or_phone_call_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No", "I spoke with someone on their team"],
    },
    {
      label: `On a scale of 1-5 how would you rate your first impression with ${agent.firstname} ${agent.lastname}?`,
      keyName: `on_a_scale_of_1_5_how_would_you_rate_your_first_impression_of_agent_${
        index + 1
      }_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    // Trigger fields
    {
      label: `What is negatively impacting your impression of ${agent.firstname} ${agent.lastname}?`,
      keyName: `what_is_negatively_impacting_your_impression_of_agent_${
        index + 1
      }_`,
      type: FieldTypes.textarea,
      triggers: {
        [`on_a_scale_of_1_5_how_would_you_rate_your_first_impression_of_agent_${
          index + 1
        }_`]: ["1", "2", "3"],
      },
    },
    {
      label: `Do you plan on scheduling an appointment with ${agent.firstname} ${agent.lastname}?`,
      keyName: `do_you_plan_on_scheduling_an_appointment_with_agent_${
        index + 1
      }_`,
      type: FieldTypes.radioText,
      triggers: {
        [`on_a_scale_of_1_5_how_would_you_rate_your_first_impression_of_agent_${
          index + 1
        }_`]: ["4", "5"],
      },
      options: ["Yes", "No", "I have already scheduled an appointment"],
    },
    {
      label: `When is your appointment?`,
      keyName: `when_is_your_appointment___agent_${index + 1}_`,
      type: FieldTypes.date,
      isEpoch: true,
      triggers: {
        [`do_you_plan_on_scheduling_an_appointment_with_agent_${index + 1}_`]:
          "I have already scheduled an appointment",
      },
    },
  ];

  return fields;
};

const RecentlyMatchedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `General Questions`,
  },
  {
    label: "In general, how satisfied are you with your agent match(es)?",
    keyName: "how_satisfied_are_you_with_your_agent_match_es__",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: "Would you like us to get you an additional agent match?",
    keyName:
      "would_you_like_us_to_get_you_an_additional_agent_match___recently_matched_",
    type: FieldTypes.radioText,
    options: ["Yes", "No"],
    triggers: {
      how_satisfied_are_you_with_your_agent_match_es__: ["1", "2", "3"],
    },
  },
  {
    label: "What qualities would you like to see in your next agent match?",
    keyName:
      "what_qualities_would_you_like_to_see_in_your_next_agent_match___recently_matched_",
    type: FieldTypes.textarea,
    triggers: {
      would_you_like_us_to_get_you_an_additional_agent_match___recently_matched_:
        "Yes",
    },
  },
  {
    label:
      "Based on your experience so far, how likely are you to refer a friend to Clever?",
    keyName:
      "how_likely_are_you_to_refer_a_friend_to_clever___recently_matched_",
    type: FieldTypes.radioNumber,
    required: true,
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
];

const getFields = (agents: Agent[]) => {
  let fields = [] as any[];
  const agentFields = agents
    .slice(0, 2)
    .map((agent, index) => RecentlyMatchedAgentDefaultFields(agent, index))
    .flat();
  const generalFields = RecentlyMatchedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
